import { auth0 } from './auth0-development'
import { Environment } from './environment.interface'

export const environment: Environment = {
  name: 'development',
  websocketUrl: 'https://frontend-pdf-export.ip-pilot.com',
  // websocketUrl: 'http://localhost:8080',
  // in case of problem with dev API use this one for develop:
  graphqlApiUrl: 'https://api.dev.ip-pilot.com/graphql',
  // graphqlApiUrl: 'http://localhost:3001/graphql',
  staticApiUrl: 'https://static.ip-pilot.com',
  trackingApiUrl: 'https://infrastructure-um-api.patent-pilot.com/userSession',
  segmentWriteKey: 'WXZvdUJnaWZJR0Z5UjFqR09mTzJVZ2pLckRiMXdrNmQ6',
  publicGAKey: '',
  appGAKey: '',
  auth0,
}
